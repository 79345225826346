import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'common/components';
import { HelpOutlinedIcon } from 'icons';

export const GoToHelpCenter: FC = () => {
  const { t } = useTranslation();

  return (
    <a title={t('helpCenterTooltip')} href="https://intercom.help/natter/en" target="_blank" rel="noreferrer">
      <IconButton size="large" variant="plain">
        <HelpOutlinedIcon />
      </IconButton>
    </a>
  );
};
