import React, { ComponentType, FC, ReactElement } from 'react';

import { KeycloakRole, useKeycloakContext } from 'store/context/KeycloakContext';

type WithKeycloakRoleDependencyProps = {
  keycloakRolesAvailability: KeycloakRole[];
};

export const withKeycloakRoleDependency =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P & WithKeycloakRoleDependencyProps> =>
  ({ keycloakRolesAvailability, ...props }): ReactElement | null => {
    const keycloak = useKeycloakContext();

    if (keycloak.tokenParsed?.realm_access?.roles.some((r) => (keycloakRolesAvailability as string[]).includes(r))) {
      return <WrappedComponent {...(props as P)} />;
    }

    return null;
  };
