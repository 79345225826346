import { type Components, type Theme } from '@mui/material';

export const MuiPopper: Components<Theme>['MuiPopper'] = {
  defaultProps: {
    modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          altBoundary: true,
          rootBoundary: 'document',
        },
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: true,
          rootBoundary: 'document',
        },
      },
    ],
    placement: 'bottom-start',
  },
  styleOverrides: {
    root: ({ theme: { zIndex, spacing } }) => ({ zIndex: zIndex.modal, padding: spacing(1, 0) }),
  },
};
