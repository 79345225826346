import { pipe, prop } from 'lodash/fp';
import type { RootState } from 'store';

import { MyEventsState } from './types';

const selectMyEventsState: (state: RootState) => RootState['myEvents'] = prop('myEvents');

export const selectMyEventsSearchQuery = pipe<RootState[], MyEventsState, string>(
  selectMyEventsState,
  prop('searchQuery')
);
