import React, { type FC, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import isValidProp from '@emotion/is-prop-valid';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { SentryRoute, SuspenseFallback } from 'common/components';
import { NatterThemeProvider } from 'common/hocs';
import { isFeatureEnabled, lazyWithRetry } from 'common/utils';
import { MotionConfig } from 'framer-motion';
import { AlertsContainer, ApiErrorAlertContainer } from 'modules/alerts/components';
import { ErrorHandler } from 'modules/app/components';
import { AppRoutes } from 'router';

import App from './App';
import { useIntercom } from './hooks';

const lazyLoadedPages = {
  TechCheck: lazyWithRetry(() => import('pages/TechCheck'), 'TechCheck'),
  PublicEventReport: lazyWithRetry(() => import('pages/PublicEventReport'), 'PublicEventReport'),
  PublicSurveyReport: lazyWithRetry(() => import('pages/PublicSurveyReport'), 'PublicSurveyReport'),
  SurveyPublic: lazyWithRetry(() => import('pages/Survey/SurveyPublic'), 'SurveyPublic'),
  EventPublic: lazyWithRetry(() => import('pages/PublicEventDetails'), 'PublicEventDetails'),
};

export const PreApp: FC = () => {
  useIntercom();

  return (
    <StyledEngineProvider injectFirst>
      <MotionConfig isValidProp={isValidProp}>
        <NatterThemeProvider>
          <CssBaseline />
          <Sentry.ErrorBoundary fallback={<ErrorHandler />}>
            <AlertsContainer context="APP" />
            <ApiErrorAlertContainer />
            <Switch>
              <SentryRoute
                path={[
                  AppRoutes.EventPublic,
                  AppRoutes.SurveyPublic,
                  AppRoutes.PublicEventReport,
                  AppRoutes.PublicSurveyReport,
                  AppRoutes.TechCheck,
                ]}
              >
                <Route path={AppRoutes.EventPublic}>
                  <Suspense fallback={<SuspenseFallback />}>
                    <SentryRoute path={AppRoutes.EventPublic} component={lazyLoadedPages.EventPublic} />
                  </Suspense>
                </Route>

                <Route path={AppRoutes.SurveyPublic}>
                  <Suspense fallback={<SuspenseFallback />}>
                    <SentryRoute path={AppRoutes.SurveyPublic} component={lazyLoadedPages.SurveyPublic} />
                  </Suspense>
                </Route>

                <Route path={AppRoutes.PublicEventReport}>
                  <Suspense fallback={<SuspenseFallback />}>
                    <SentryRoute path={AppRoutes.PublicEventReport} component={lazyLoadedPages.PublicEventReport} />
                  </Suspense>
                </Route>

                <Route path={AppRoutes.PublicSurveyReport}>
                  <Suspense fallback={<SuspenseFallback />}>
                    <SentryRoute path={AppRoutes.PublicSurveyReport} component={lazyLoadedPages.PublicSurveyReport} />
                  </Suspense>
                </Route>

                <Route path={AppRoutes.TechCheck}>
                  <Suspense fallback={<SuspenseFallback />}>
                    {!isFeatureEnabled('TechCheck') && <Redirect to="/" />}
                    <SentryRoute path={AppRoutes.TechCheck} component={lazyLoadedPages.TechCheck} />
                  </Suspense>
                </Route>
              </SentryRoute>

              <SentryRoute strict path="/" component={App} />
            </Switch>
          </Sentry.ErrorBoundary>
        </NatterThemeProvider>
      </MotionConfig>
    </StyledEngineProvider>
  );
};
