import React, { FC, MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Button, UserAvatar } from 'common/components';
import { Account } from 'domain/Account';
import { ChevronDownIcon, LogoutIcon, SettingsAccountIcon } from 'icons';
import { AppRoutes } from 'router';

type Props = {
  account: Account;
  onLogout(): void;
};

export const CurrentUser: FC<Props> = ({ account, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { displayName, email, avatarLink } = account;

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box data-testid="current-user">
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Button
          variant="text"
          onClick={handleMenu}
          startIcon={<UserAvatar name={displayName} avatarLink={avatarLink} dimensions={24} />}
          endIcon={<ChevronDownIcon fontSize="small" color="action" />}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          disableRipple
          sx={{ mr: -1 }}
        >
          <Typography color="text.secondary">{displayName}</Typography>
        </Button>
      </Stack>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="current-user-menu"
        MenuListProps={{ sx: { px: 0 } }}
      >
        <Stack gap={0.5} p={1.5} pt={1} width={240} aria-label="User information">
          <Typography variant="natter-text-md" fontWeight={500}>
            {displayName}
          </Typography>
          <Typography variant="natter-text-xs" color="text.secondary">
            {email}
          </Typography>
        </Stack>
        <Divider />
        <Stack mt={0.75}>
          <MenuItem component={NavLink} to={AppRoutes.Account} onClick={handleClose}>
            <ListItemIcon>
              <SettingsAccountIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Account settings</ListItemText>
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Log out</ListItemText>
          </MenuItem>
        </Stack>
      </Menu>
    </Box>
  );
};
