import React, { FC, useMemo } from 'react';

import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { BarChartLabel, Tick, TooltipContentWrapper } from '../../components';
import { CHART_BAR_MAX_WIDTH, CHART_MIN_HEIGHT, legendWrapperStyle } from '../../constants';
import { useChartAxis, useChartColors, useChartStyles, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateChartMargins, calculateTicks, mapToStackedChartValues, tickFormatter } from '../../utils';

export const VerticalStackedBarChart: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  TooltipContent,
  legendFormatter,
}) => {
  const { getChartClass } = useChartStyles();
  const { getColor } = useChartColors();
  const { tickStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const { activeKey, handleMouseEnter, hideTooltip } = useTooltip();
  const stackedValues = useMemo(() => mapToStackedChartValues({ data, dataKeys }), [data, dataKeys]);
  const { ticks, isLastTickEqualToMaxValue } = useMemo(() => calculateTicks({ data: stackedValues }), [stackedValues]);

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <BarChart
        data={data}
        margin={calculateChartMargins({ labelNames, labelValues })}
        className={getChartClass('vertical', isLastTickEqualToMaxValue)}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          tick={(props) => <Tick {...props} axis="X" />}
          tickMargin={16}
          interval={0}
          label={renderAxisXLabel(labelNames)}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          interval={0}
          type="number"
          domain={[0, 'dataMax']}
          ticks={ticks}
          allowDataOverflow
          allowDecimals={false}
          tickCount={10}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          tickFormatter={tickFormatter}
        />
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataKey={activeKey} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && (
          <Legend verticalAlign="top" align="right" formatter={legendFormatter} wrapperStyle={legendWrapperStyle} />
        )}

        {dataKeys.map((key, index) => {
          const color = getColor(index);

          return (
            <Bar
              key={key}
              stackId="stack1"
              dataKey={key}
              maxBarSize={CHART_BAR_MAX_WIDTH}
              fill={color}
              onMouseEnter={handleMouseEnter(key)}
              onMouseLeave={hideTooltip}
            >
              <LabelList dataKey={key} fill={color} content={BarChartLabel} />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
