import React, { FC } from 'react';

import { useAppSelector } from 'store';
import { selectMyCommunity } from 'store/apis/community';

import { CommunityLogo } from './CommunityLogo';

export const CommunityLogoContainer: FC = () => {
  const myCommunity = useAppSelector(selectMyCommunity);

  return <CommunityLogo community={myCommunity} />;
};
