import React, { FC, ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';

export const ActionBar: FC<{
  title: ReactNode;
}> = ({ title, children }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={({ spacing, palette }) => ({
      backgroundColor: palette.grey[50],
      padding: spacing(1.25, 3),
      borderRadius: '26px',
      width: '100%',
    })}
  >
    {typeof title === 'string' ? (
      <Typography variant="natter-text-sm" fontWeight={500}>
        {title}
      </Typography>
    ) : (
      title
    )}
    <Stack direction="row" ml="auto" gap={2} alignItems="center">
      <Stack direction="row" gap={2} alignItems="center">
        {children}
      </Stack>
    </Stack>
  </Stack>
);
