import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

export type ExpandableBoxWrapperProps = AccordionProps & {
  size?: 'small' | 'medium';
};

export const ExpandableBoxWrapper = styled(Accordion, {
  shouldForwardProp: omitForwardedProps('size'),
})<ExpandableBoxWrapperProps>(({ theme: { spacing, shape }, size }) => ({
  '&.MuiPaper-root': {
    padding: size === 'small' ? spacing(2) : spacing(3),
    borderRadius: size === 'small' ? shape.borderRadius : shape.borderRadius * 2,
  },
}));

export const ExpandableBoxSummary = styled(AccordionSummary)(({ theme: { spacing, typography } }) => ({
  padding: 0,
  flexDirection: 'row-reverse',
  minHeight: 'unset',

  '&.Mui-expanded': {
    marginBottom: spacing(1.5),
  },

  '& .MuiAccordionSummary-content': {
    ...typography['natter-text-xl'],

    margin: spacing(0.25, 0, 0, 1),
    gap: spacing(2),
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'inherit',
    paddingTop: spacing(0.25),

    alignSelf: 'start',
    '&.Mui-expanded': {
      transform: 'rotate(90deg)',
      paddingTop: 0,
      paddingLeft: spacing(0.25),
    },
  },
}));

export const ExpandableBoxContent = styled(AccordionDetails)(({ theme: { spacing } }) => ({
  padding: spacing(1, 0, 0),
}));
