import { useCallback, useState } from 'react';

/**
 * Custom React hook for managing a toggle state.
 *
 * @param {boolean | (() => boolean)} initialState - The initial state value or a function that returns the initial state value.
 * @returns {[boolean, (boolean) => void]} - An array containing the current state value and a function to toggle the state.
 */
export const useToggleState = (initialState: boolean | (() => boolean)): [boolean, (forceState?: boolean) => void] => {
  const [state, setState] = useState(initialState);
  const toggleState = useCallback((forceState?: boolean) => setState((value) => forceState ?? !value), []);

  return [state, toggleState];
};
