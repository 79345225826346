import { Color, PaletteMode } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles';

import { blue, green, grey, red } from './colors';

export const DEFAULT_PRIMARY_COLOR = blue[600];

export const customColors = {
  darkModeNotificationBackground: '#404144',
};

export const palette = (primary = DEFAULT_PRIMARY_COLOR, mode: PaletteMode = 'light'): Partial<PaletteOptions> => ({
  mode,
  contrastThreshold: 2.38,
  primary: {
    main: primary,
  },
  extendedPrimary: blue,
  secondary: {
    main: grey[100],
    dark: grey[200],
    contrastText: grey[700],
  },
  info: {
    light: blue[50],
    main: blue[600],
    dark: blue[700],
    contrastText: blue[600],
  },
  success: {
    main: green[600],
    dark: green[700],
    light: green[50],
  },
  error: {
    main: red[600],
    dark: red[700],
    light: red[50],
    contrastText: '#fff',
  },
  action: {
    active: grey[700],
    disabledBackground: grey[100],
    disabled: grey[400],
  },
  divider: grey[200],
  grey: grey as Partial<Color>,
  text: {
    primary: grey[900],
    secondary: grey[600],
    disabled: grey[300],
  },
  background: {
    default: grey[25],
    paper: '#fff',
  },
});
