/**
 * Asserts that the given condition is truthy. If the condition is falsy, an error is thrown.
 * @param {any} condition - The condition to be checked.
 * @param {string} [msg] - The error message to be thrown if the condition is falsy.
 * @throws {Error} If the condition is falsy.
 *
 * @example
 * const result = 42;
 * assert(result === 42, 'Result should be 42');
 * // No error is thrown
 *
 * @example
 * const result = 42;
 * assert(result === 0, 'Result should be 0');
 * // Throws an error: "Result should be 0"
 */
export function assert(condition: unknown, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}
