/* eslint-disable functional/no-let */
import { convertNumberToAbbreviation } from 'common/utils';
import { maxBy, sortBy, uniq } from 'lodash';

import { chartMargins } from '../constants';
import { BasicChartData, BasicChartDataPoint, chartNonDataKeys } from '../types';

export const calculateTicks = ({
  data,
  dataKeys = ['value'],
}: {
  data: Record<string, number | string | undefined>[];
  dataKeys?: string[];
}) => {
  const maxValue = calculateMaxValue(data, dataKeys);
  let interval = calculateInterval(maxValue);
  let numberOfTicks = Math.ceil(maxValue / interval) + 1;
  if (numberOfTicks < 6 && interval > 1) {
    interval = interval / 2;
    numberOfTicks = numberOfTicks * 2;
  }
  const basicTicks = Array.from({ length: numberOfTicks }, (_, i) => i * interval).filter((v) => v <= maxValue);
  const isLastTickEqualToMaxValue = basicTicks[basicTicks.length - 1] === maxValue;

  return {
    ticks: sortBy(uniq([...basicTicks, maxValue])),
    isLastTickEqualToMaxValue,
  };
};

export const calculateMaxValue = (
  data: Record<string, number | string | undefined>[],
  dataKeys: string[] = ['value']
) => {
  const maxValue = Number(maxBy(dataKeys.map((dataKey) => maxBy(data, dataKey)?.[dataKey])));
  return isNaN(maxValue) ? 0 : maxValue;
};

export const calculateInterval = (value: number) => {
  // https://stackoverflow.com/questions/23917074/javascript-flooring-number-to-order-of-magnitude
  const order = Math.floor(Math.log(Math.max(value - 1, 1)) / Math.LN10 + 0.000000001);
  return 10 ** order;
};

export const initFrequencyDistributionData = ({
  dataKeys,
  xAxisDomain,
}: {
  xAxisDomain: [number, number];
  dataKeys: string[];
}) => {
  const min = xAxisDomain[0] - 1;
  const max = xAxisDomain[1];
  const distance = max - min;
  const numberOfBuckets = Math.min(distance, 10);
  const interval = distance / numberOfBuckets;
  const numberOfTicks = numberOfBuckets;
  let x1 = min;
  const buckets = Array.from({ length: numberOfTicks }).map((_, index) => {
    const tick = x1 + 1;
    x1 = min + Math.floor((index + 1) * interval);
    const histogramDataPoint: BasicChartDataPoint = {
      name: index,
      x0: tick,
      x1,
    };
    dataKeys.forEach((dataKey) => {
      histogramDataPoint[dataKey] = 0;
    });
    return histogramDataPoint;
  });

  return {
    buckets,
    interval,
  };
};

export const distributeFrequency = ({
  initialData,
  buckets,
  dataKeys,
}: {
  initialData: BasicChartData;
  buckets: BasicChartData;
  dataKeys: string[];
}) => {
  initialData.forEach((dataPoint) => {
    dataKeys.forEach((dataKey) => {
      const value = Number(dataPoint.name);
      const count = Number(dataPoint[dataKey]);
      if (isNaN(value) || isNaN(count)) return;

      const bucket = buckets.findIndex(
        (tick) => typeof tick.x0 === 'number' && typeof tick.x1 === 'number' && tick.x0 <= value && tick.x1 >= value
      );
      if (typeof buckets[bucket][dataKey] === 'number') {
        buckets[bucket][dataKey] += count;
      }
    });
  });
};

export const mapToStackedChartValues = ({
  data,
  dataKeys = ['value'],
}: {
  data: BasicChartData;
  dataKeys?: string[];
}): { value: number }[] =>
  data.map((dataPoint) => ({
    value: dataKeys.reduce((sum, currentKey) => {
      const currentValue = dataPoint[currentKey];
      if (typeof currentValue !== 'number') return sum;
      return sum + currentValue;
    }, 0),
  }));

export const mapToStackedChartPercentages = (data: BasicChartData): BasicChartData =>
  data.map((dataPoint) => {
    const entries = Object.entries(dataPoint);
    const totalValue = entries
      .filter(([key]) => !isChartNonDataKey(key))
      .reduce((total, [, value]) => total + (Number(value) || 0), 0);

    return entries.reduce((newDataPoint, [key, value]) => {
      if (key === 'name') {
        newDataPoint[key] = value!;
      } else {
        const percentageValue = ((Number(value) || 0) * 100) / totalValue;
        newDataPoint[key] = isNaN(percentageValue) ? 0 : percentageValue;
      }
      return newDataPoint;
    }, {} as BasicChartDataPoint);
  });

export const isChartNonDataKey = (key: string) => chartNonDataKeys.includes(key);

export const calculateChartMargins = ({ labelNames, labelValues }: { labelNames?: string; labelValues?: string }) => ({
  ...chartMargins,
  left: labelNames ? chartMargins.left : 10,
  bottom: labelValues ? chartMargins.bottom : 10,
});

export const tickFormatter = (value: unknown, index?: number, isPercentageValue?: boolean): string =>
  isNaN(Number(value)) ? `${value}` : `${convertNumberToAbbreviation(Number(value))}${isPercentageValue ? '%' : ''}`;
