import { createReducer } from '@reduxjs/toolkit';

import { myEventsActions } from './myEvents.actions';
import { MyEventsState } from './types';

const initialState: MyEventsState = {
  searchQuery: '',
};

export const myEventsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(myEventsActions.unmounted, () => initialState)
    .addCase(myEventsActions.search.input.changed, (state, action) => {
      state.searchQuery = action.payload;
    });
});
