import React, { FC } from 'react';

import { Stack } from '@mui/material';
import { Dialog } from 'common/components';
import { WarningOutlinedIcon } from 'icons';

import { FullPageContainer } from '../FullPageContainer';

export const ErrorHandler: FC<{
  title?: string;
  description?: string;
}> = ({ title = 'Oops, something went wrong...', description = 'Looks like there was some unexpected error.' }) => (
  <FullPageContainer>
    <Dialog isOpen title={title} showCloseButton={false} showCancelButton={false} centered>
      <Stack alignItems="center" gap={2}>
        <WarningOutlinedIcon fontSize="large" color="error" />
        <div>{description}</div>
        <div>
          Refresh the page or contact the Support Team{' '}
          <a href="https://intercom.help/natter/en/" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </div>
      </Stack>
    </Dialog>
  </FullPageContainer>
);
