import React, { FC, useMemo } from 'react';

import { useTheme } from '@mui/styles';
import { convertNumberToAbbreviation } from 'common/utils';
import { LabelProps } from 'recharts';

import { CHART_LABEL_FONT_SIZE, CHART_LABEL_FONT_WEIGHT, CHART_TICK_PADDING } from '../constants';

type BarChartLabelProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number | string;
};

type Props = LabelProps & {
  isPercentageValue?: boolean;
};

export const BarChartLabel: FC<Props> = (props) => {
  const { palette, typography } = useTheme();
  const { fill = '', isPercentageValue } = props;
  const { x, y, height, width, value } = props as BarChartLabelProps;
  const labelPositionX = x + width / 2;
  const labelPositionY = y + height / 2;
  const formattedValue = useMemo(
    () => (typeof value === 'number' ? convertNumberToAbbreviation(value) : value),
    [value]
  );

  const { fitsHorizontally, fitsVertically } = useMemo(() => {
    const element = initTemporaryHtmlElement(typography.fontFamily, formattedValue);
    const { clientHeight, clientWidth } = element;
    document.body.removeChild(element);
    return {
      fitsHorizontally: clientWidth < width && clientHeight < height,
      fitsVertically: clientWidth < height && clientHeight < width,
    };
  }, [typography, formattedValue, width, height]);

  const shouldRotate = !fitsHorizontally && fitsVertically;

  if (!(fitsHorizontally || fitsVertically)) return null;

  return (
    <text
      x={labelPositionX}
      y={labelPositionY}
      fill={palette.getContrastText(fill)}
      textAnchor="middle"
      dominantBaseline="central"
      transform={shouldRotate ? `rotate(-90, ${labelPositionX}, ${labelPositionY})` : undefined}
      fontSize={CHART_LABEL_FONT_SIZE}
      fontWeight={CHART_LABEL_FONT_WEIGHT}
      style={{ pointerEvents: 'none' }}
    >
      {`${formattedValue}${isPercentageValue ? '%' : ''}`}
    </text>
  );
};

const initTemporaryHtmlElement = (fontFamily: string | undefined, value: string | number) => {
  const element = document.createElement('div');
  element.style.visibility = 'hidden';
  element.style.fontFamily = fontFamily ?? '';
  element.style.fontSize = CHART_LABEL_FONT_SIZE;
  element.style.fontWeight = CHART_LABEL_FONT_WEIGHT.toString();
  element.style.display = 'inline-block';
  element.style.paddingLeft = `${CHART_TICK_PADDING}px`;
  element.style.paddingRight = `${CHART_TICK_PADDING}px`;
  element.innerHTML = value?.toString() ?? '';
  document.body.appendChild(element);
  return element;
};
