import { ComponentProps, ElementType } from 'react';
import { NavLink } from 'react-router-dom';

import { ListItem, ListItemProps, alpha, styled } from '@mui/material';

type Props = ListItemProps & ComponentProps<typeof NavLink> & { component?: ElementType };

export const NavigationItem = styled(ListItem)<Props>(({ theme: { palette, spacing, shape } }) => ({
  display: 'flex',
  gap: spacing(1.5),
  alignItems: 'center',

  margin: spacing(0.25, 0),
  padding: spacing(1),
  borderRadius: shape.borderRadius,

  transition: 'all .2s cubic-bezier(.17,.67,.83,.67)',

  color: palette.text.secondary,

  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
    color: 'inherit',
  },

  '&.active': {
    color: palette.primary.main,
    background: alpha(palette.primary.main, 0.05),
  },
}));
