import { createAction } from '@reduxjs/toolkit';

export const myEventsActions = {
  unmounted: createAction('[My Events] unmounted'),
  search: {
    input: {
      changed: createAction<string>('[My Events Search Input] changed'),
    },
  },
};
