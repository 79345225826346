import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { UserAvatar } from 'common/components';
import { useAppSelector } from 'store';
import { selectCurrentCommunityMember } from 'store/apis/community';
import { selectUserAccount } from 'store/apis/user';

export const SidebarProfile: FC = () => {
  const userAccount = useAppSelector(selectUserAccount);
  const communityMember = useAppSelector(selectCurrentCommunityMember);

  if (!userAccount) return null;

  const { displayName, avatarLink, email } = userAccount;

  return (
    <Stack flexDirection="row" gap={2} data-testid="SidebarProfile">
      <UserAvatar dimensions={72} name={displayName} avatarLink={avatarLink} />

      <Stack alignItems="start">
        <Typography variant="natter-text-md" fontWeight={600}>
          {displayName}
        </Typography>
        {communityMember?.role && (
          <Typography variant="natter-text-xs" color="text.secondary">
            {communityMember.role}
          </Typography>
        )}
        <Typography variant="natter-text-xs" color="text.secondary">
          {email}
        </Typography>
      </Stack>
    </Stack>
  );
};
