import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedApiPayloadV3, PaginatedApiResponse } from 'domain/Common';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';

export const nattersApi = createApi({
  reducerPath: 'nattersApi',
  tagTypes: ['NATTERS_LIST'],
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v1') }),
  endpoints: (builder) => ({
    getNattersList: builder.query<PaginatedApiResponse<unknown>, { communityId: number } & PaginatedApiPayloadV3>({
      providesTags: ['NATTERS_LIST'],
      queryFn: () =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              data: {
                data: [],
                totalCount: 0,
              },
            });
          }, 500);
        }),
    }),
  }),
});

export const { useGetNattersListQuery } = nattersApi;
