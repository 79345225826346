import { makeStyles } from '@mui/styles';

export const useChartStyles = () => {
  const classes = generateStyles();

  return {
    ...classes,
    getChartClass: (orientation: 'vertical' | 'horizontal', showLastTick: boolean) => {
      if (showLastTick) return;

      return orientation === 'horizontal' ? classes.lastXAxisTickHidden : classes.lastYAxisTickHidden;
    },
  };
};

const generateStyles = makeStyles(
  ({ palette, typography }) => {
    const chart = {
      '& .recharts-legend-item-text': {
        color: `${palette.text.primary} !important`,
        lineHeight: '16px',
        fontSize: '11px',
        fontFamily: typography.fontFamily,
      },
    };

    return {
      chart,
      lastXAxisTickHidden: {
        ...chart,
        '& .recharts-cartesian-grid-vertical line:last-of-type': {
          display: 'none',
        },
        '& .xAxis .recharts-cartesian-axis-tick:last-of-type': {
          display: 'none',
        },
      },
      lastYAxisTickHidden: {
        ...chart,
        '& .recharts-cartesian-grid-horizontal line:last-of-type': {
          display: 'none',
        },
        '& .yAxis .recharts-cartesian-axis-tick:last-of-type': {
          display: 'none',
        },
      },
    };
  },
  {
    name: 'chartStyles',
  }
);
