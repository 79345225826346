import React, { FC } from 'react';

import { Sidebar } from 'common/components';

import { SidebarContent } from './components';

export const DashboardSidebar: FC = () => (
  <Sidebar>
    <SidebarContent />
  </Sidebar>
);
