import React, { ComponentType, FC, useMemo, useState } from 'react';
import { Redirect, RouteProps, matchPath, useLocation } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { SentryRoute } from 'common/components';
import { UserPrivilegeName } from 'domain/UserProfile';
import { useAuthorization } from 'modules/authorization/hooks';

export const ProtectedRoute: FC<
  RouteProps<string> & {
    allowedFor?: UserPrivilegeName[];
    component?: ComponentType<unknown>;
    redirectRoute?: string;
    withNoCommunity?: boolean;
    withCommunity?: boolean;
    keepInitialRole?: boolean;
  }
> = ({
  allowedFor,
  component,
  path,
  redirectRoute = '/',
  withNoCommunity = false,
  withCommunity = false,
  keepInitialRole = false,
}) => {
  const { hasPrivilege, isLoading, isWithoutCommunity } = useAuthorization();
  const { pathname } = useLocation();
  const [initialIsAllowed] = useState<boolean>(
    (allowedFor ? hasPrivilege(allowedFor) : false) || (withNoCommunity && isWithoutCommunity)
  );
  const isAllowed = useMemo(
    () =>
      (allowedFor ? hasPrivilege(allowedFor) : false) ||
      (withNoCommunity && isWithoutCommunity) ||
      (withCommunity && !isWithoutCommunity),
    [allowedFor, hasPrivilege, withNoCommunity, isWithoutCommunity, withCommunity]
  );
  const isPathMatching = matchPath(pathname, { path });

  if (isLoading) {
    return <CircularProgress style={{ margin: 'auto' }} size={50} />;
  }

  if (isPathMatching && (keepInitialRole ? !initialIsAllowed : !isAllowed)) {
    return <Redirect to={redirectRoute} />;
  }

  return <SentryRoute path={path} component={component} />;
};
