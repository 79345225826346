import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedApiPayloadV3, PaginatedApiResponse } from 'domain/Common';
import { EventQuestionDefinition } from 'domain/event';
import { Answer, CreateAnswerRequest } from 'domain/question';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import {
  AsyncSurvey,
  AsyncSurveyListItem,
  AsyncSurveyListSortField,
  AsyncSurveyPublic,
  CreateSurveyPayload,
  CreateSurveyQuestionsPayload,
  CreateSurveyUsersPayload,
  UpdateSurveyPayload,
  WithSurveyId,
} from './types';
import { mapSurveyFromApi, mapSurveysListResponse } from './utils';

export const surveyApi = createApi({
  reducerPath: 'surveyApi',
  tagTypes: ['SURVEYS_LIST', 'SURVEY_DETAILS', 'SURVEY_QUESTIONS', 'SURVEY_DETAILS_PUBLIC'],
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v1') }),
  endpoints: (builder) => ({
    getSurveysList: builder.query<
      PaginatedApiResponse<AsyncSurveyListItem>,
      { communityId: number } & PaginatedApiPayloadV3<AsyncSurveyListSortField>
    >({
      providesTags: ['SURVEYS_LIST'],
      query: ({ communityId, ...args }) => ({ url: `/survey/surveys/${communityId}`, params: args }),
      transformResponse: mapSurveysListResponse,
    }),
    createSurvey: builder.mutation<AsyncSurvey, CreateSurveyPayload>({
      query: (data) => ({ url: '/survey', method: 'POST', data }),
      invalidatesTags: ['SURVEYS_LIST'],
    }),
    updateSurvey: builder.mutation<boolean, UpdateSurveyPayload>({
      query: ({ id, ...data }) => ({ url: `/survey/${id}`, method: 'PATCH', data }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'SURVEY_DETAILS', id: arg.id }],
    }),
    getSurvey: builder.query<AsyncSurvey, WithSurveyId>({
      providesTags: (_result, _error, arg) => [{ type: 'SURVEY_DETAILS', id: arg.id }],
      query: ({ id }) => ({ url: `/survey/${id}` }),
      transformResponse: mapSurveyFromApi,
    }),
    getPublicSurvey: builder.query<AsyncSurveyPublic, WithSurveyId>({
      providesTags: (_result, _error, arg) => [{ type: 'SURVEY_DETAILS_PUBLIC', id: arg.id }],
      query: ({ id }) => ({ url: `/survey/${id}/public` }),
      transformResponse: mapSurveyFromApi,
    }),
    publishSurvey: builder.mutation<AsyncSurvey['id'], WithSurveyId>({
      query: ({ id }) => ({ url: `/survey/${id}/publish`, method: 'POST' }),
      transformResponse: (response: boolean, error, meta) => meta.id,
      invalidatesTags: (_result, _error, arg) => ['SURVEYS_LIST', { type: 'SURVEY_DETAILS', id: arg.id }],
    }),
    unpublishSurvey: builder.mutation<AsyncSurvey, WithSurveyId>({
      query: ({ id }) => ({ url: `/survey/${id}/unpublish`, method: 'POST' }),
      invalidatesTags: (_result, _error, arg) => ['SURVEYS_LIST', { type: 'SURVEY_DETAILS', id: arg.id }],
    }),
    createSurveyQuestions: builder.mutation<void, CreateSurveyQuestionsPayload>({
      query: ({ id, ...data }) => ({ url: `/survey/${id}/questions`, method: 'POST', data }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'SURVEY_QUESTIONS', id: arg.id }],
    }),
    getSurveyQuestions: builder.query<EventQuestionDefinition[], WithSurveyId>({
      providesTags: (_result, _error, arg) => [{ type: 'SURVEY_QUESTIONS', id: arg.id }],
      query: ({ id }) => ({ url: `/survey/${id}/questions` }),
    }),
    createSurveyUsers: builder.mutation<void, CreateSurveyUsersPayload>({
      query: ({ id, ...data }) => ({ url: `/survey/${id}/users`, method: 'POST', data }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'SURVEY_DETAILS', id: arg.id }],
    }),
    deleteSurvey: builder.mutation<void, WithSurveyId>({
      query: ({ id }) => ({ url: `/survey/${id}`, method: 'DELETE' }),
      invalidatesTags: ['SURVEYS_LIST'],
    }),
    getSurveyAnswers: builder.query<Answer[], WithSurveyId>({
      query: ({ id }) => ({ url: `/survey/${id}/answers` }),
    }),
    createSurveyAnswers: builder.mutation<void, WithSurveyId<{ answers: CreateAnswerRequest[] }>>({
      query: ({ id, answers }) => ({ url: `/survey/${id}/answers`, method: 'POST', data: answers }),
    }),
  }),
});

export const {
  useGetSurveysListQuery,
  useCreateSurveyMutation,
  useGetSurveyQuery,
  useGetPublicSurveyQuery,
  useGetSurveyQuestionsQuery,
  useDeleteSurveyMutation,
  useGetSurveyAnswersQuery,
  useCreateSurveyAnswersMutation,
} = surveyApi;
