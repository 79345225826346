import React, { FC, createContext, useContext, useState } from 'react';

import { assert } from 'common/utils';
import { isArray, uniq } from 'lodash';

import { Step } from './components';
import { getStepStatus } from './utils';

type Props = {
  steps: Step[];
};

const useTaskStepperContextValue = ({ steps: stepsDefinition }: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState<number[]>([]);
  const [stepsWithErrors, setStepsWithErrors] = useState<number[]>([]);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleReset = (onReset: () => void) => () => {
    onReset?.();
    setActiveStep(0);
    setVisitedSteps([]);
    setStepsWithErrors([]);
  };

  const handleStepsWithErrors = (step: number | number[]) =>
    setStepsWithErrors((prev) => uniq([...prev, ...(isArray(step) ? step : [step])]));

  const handleVisitedStep = (step: number | number[]) =>
    setVisitedSteps((prev) => uniq([...prev, ...(isArray(step) ? step : [step])]));

  const steps = stepsDefinition.map((stepDefinition, index) => ({
    ...stepDefinition,
    status: getStepStatus({
      currentStep: index,
      activeStep,
      stepsWithErrors,
      visitedSteps,
    }),
  }));

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === steps.length - 1;

  return {
    activeStep,
    visitedSteps,
    handleStepsWithErrors,
    handleVisitedStep,
    handleStepChange,
    handleReset,
    isFirstStep,
    isLastStep,
    steps,
  };
};

type TaskStepperContextValue = ReturnType<typeof useTaskStepperContextValue>;

export const TaskStepperContext = createContext<TaskStepperContextValue | undefined>(undefined);

export const TaskStepperContextProvider: FC<Props> = ({ children, ...props }) => {
  const value = useTaskStepperContextValue(props);

  return <TaskStepperContext.Provider value={value}>{children}</TaskStepperContext.Provider>;
};

export const useTaskStepperContext = () => {
  const ctx = useContext(TaskStepperContext);
  assert(ctx, 'useTaskStepperContext must be used within a TaskStepperContextProvider');
  return ctx;
};
