import React, { FC, ReactNode } from 'react';

import { Link, Stack, SxProps, Typography } from '@mui/material';

import { Button } from '../Buttons';
import { AlertWrapper, iconsMap } from './Alert.styled';
import { AlertVariant } from './types';

type Action = {
  label: string;
  onClick?(): void;
  href?: string;
};

type Props = {
  title?: string;
  variant?: AlertVariant;
  description?: ReactNode;
  children?: ReactNode | undefined;
  actions?: Action[];
  sx?: SxProps;
};

export const Alert: FC<Props> = ({
  variant = AlertVariant.Transparent,
  actions = [],
  title,
  description,
  children,
  sx,
}) => {
  const Icon = iconsMap[variant];

  return (
    <AlertWrapper data-testid={`alert-${variant}`} variant={variant} sx={sx}>
      <Icon
        sx={{
          fontSize: title ? '24px' : '20px',
        }}
      />

      <Stack alignItems="start">
        {title && (
          <Typography variant="natter-text-md" component="h2" mb={1}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="natter-text-sm" color="text.secondary" component="span" whiteSpace="pre-wrap">
            {description}
          </Typography>
        )}

        {children}

        {actions.length > 0 && (
          <Stack direction="row" gap={3} mt={2}>
            {actions.map(({ label, href, onClick }, index) =>
              href ? (
                <Link key={index} href={href} sx={({ typography }) => ({ ...typography['natter-text-sm'] })}>
                  <Typography noWrap>{label}</Typography>
                </Link>
              ) : (
                <Button key={index} size="small" onClick={onClick}>
                  {label}
                </Button>
              )
            )}
          </Stack>
        )}
      </Stack>
    </AlertWrapper>
  );
};
