import React, { FC, ReactNode, useEffect, useState } from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

import { Typography } from '@mui/material';
import { UserRoleName } from 'domain/UserProfile';
import { KeycloakRole } from 'store/context/KeycloakContext';

import { NavigationItem } from './NavigationLinkItem.styled';

type NavigationLinkItemProps = {
  icon: ReactNode;
  label: string;
  activePath: string[] | string;
  to: string;
  rolesAvailability?: UserRoleName[];
  keycloakRolesAvailability?: KeycloakRole[];
  visibleOnlyOnActivePath?: boolean;
};

export const NavigationLinkItem: FC<NavigationLinkItemProps> = ({
  icon,
  label,
  activePath,
  to,
  visibleOnlyOnActivePath = false,
}) => {
  const [isPathActive, setIsPathActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsPathActive(!!matchPath(location.pathname, { path: activePath }));
  }, [location.pathname, activePath]);

  if (visibleOnlyOnActivePath && !isPathActive) {
    return null;
  }

  return (
    <NavigationItem to={to} component={NavLink}>
      {icon}

      <Typography variant="natter-text-sm" noWrap>
        {label}
      </Typography>
    </NavigationItem>
  );
};
