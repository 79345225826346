import { ElementType } from 'react';

import { Box, Divider, Typography, TypographyProps, styled } from '@mui/material';
import { Button } from 'common/components';

export const CommunityLogoWrapper = styled(Box, { name: 'CommunityLogoWrapper' })(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  maxWidth: spacing(9.25),
}));

export const TitleWrapper = styled(Box, { name: 'TitleWrapper' })(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  paddingLeft: spacing(1),
  overflow: 'hidden',
}));

export const TitleDivider = styled(Divider, { name: 'TitleDivider' })({
  height: 30,
  marginRight: 1,
});

TitleDivider.defaultProps = {
  variant: 'middle',
  orientation: 'vertical',
};

export const TitleElement = styled(Typography, { name: 'TitleElement' })<TypographyProps & { component: ElementType }>(
  ({ theme: { typography, spacing, palette }, children }) => ({
    ...typography['natter-text-sm'],
    flex: 1,
    overflow: 'hidden',
    color: palette.grey[900],

    paddingLeft: typeof children === 'string' ? spacing(2) : spacing(1),
  })
);

TitleElement.defaultProps = {
  noWrap: true,
};

export const ExitButton = styled(Button, { name: 'ExitButton' })({
  flexShrink: 0,
  marginLeft: 'auto',
});

ExitButton.defaultProps = {
  variant: 'outlined',
};

export const SecondRowWrapper = styled(Box)(({ theme: { spacing } }) => ({
  padding: spacing(0, 2, 2, 2),
}));
