import React, { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { CommunityLogo } from 'common/layout';
import { textEllipsis } from 'common/theme/typography';
import { Community } from 'domain/Community';

type Props = {
  community: Community;
  isActive?: boolean;
};

export const CommunitySelectorItem: FC<Props> = ({ community, isActive }) => (
  <Stack direction="row" alignItems="center" minWidth={0}>
    <Box
      sx={({ palette }) => ({
        border: '2px solid',
        borderColor: isActive ? palette.primary.main : 'transparent',
        borderRadius: 2,
        padding: 0.25,
      })}
    >
      <CommunityLogo community={community} variant="square" size="small" />
    </Box>
    <Stack minWidth={0} alignItems="flex-start" ml={1} textAlign="start">
      <Typography variant="natter-text-sm" fontWeight="bold" sx={{ ...textEllipsis }} title={community.name}>
        {community.name}
      </Typography>
    </Stack>
  </Stack>
);
