import { useCallback } from 'react';

import { lighten } from '@mui/material';
import { blue } from 'common/theme/palette';
import { changeHue } from 'common/utils';

const HUE_COEFFICIENT = 0.17;
const LIGHTEN_COEFFICIENT = 0.12;
const NUMBER_OF_TONES = 5;
const NUMBER_OF_PALETTES = 9;

const generateTones = (hueCoefficient: number) => {
  const newColor = changeHue(blue[800], hueCoefficient);
  return Array.from({ length: NUMBER_OF_TONES }).map((_, i) => lighten(newColor, LIGHTEN_COEFFICIENT * i));
};

const generateColors = () =>
  Array.from({ length: NUMBER_OF_PALETTES }).reduce((current: string[], _, i) => {
    current.push(...generateTones(HUE_COEFFICIENT * (i + 1)));
    return current;
  }, []);

const colorsArray = [blue[800], blue[600], blue[400], blue[200], blue[100], ...generateColors()];

export const useChartColors = () => {
  const getColor = useCallback((index = 0): string => colorsArray[index % colorsArray.length], []);

  return { getColor };
};
