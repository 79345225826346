import React, { FC, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import { selectMyCommunity, useGetMyCommunitiesQuery } from 'store/apis/community';
import { setActiveCommunityId } from 'store/features/community';

import { CommunitySelectorView } from './CommunitySelectorView';

export const CommunitySelectorContainer: FC = () => {
  const dispatch = useAppDispatch();
  const activeCommunity = useAppSelector(selectMyCommunity);
  const { data } = useGetMyCommunitiesQuery();
  const { myCommunities = [] } = data ?? {};

  const onCommunityClick = useCallback(
    (communityId: number) => dispatch(setActiveCommunityId(communityId)),
    [dispatch]
  );

  return (
    <CommunitySelectorView
      activeCommunity={activeCommunity}
      communities={myCommunities}
      onCommunityClick={onCommunityClick}
    />
  );
};
