import React, { ComponentProps, FC } from 'react';

import { Menu } from 'common/components';

type Props = Pick<ComponentProps<typeof Menu>, 'items' | 'onClose'> & {
  anchorEl: HTMLButtonElement | null;
};

export const CommunitySelectorMenu: FC<Props> = ({ items, anchorEl, onClose }) => (
  <Menu
    slotProps={{ paper: { sx: { maxWidth: 300, maxHeight: 400 } } }}
    anchor={anchorEl}
    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    items={items}
    onClose={onClose}
  />
);
