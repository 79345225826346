import React, { FC, memo } from 'react';

import { AppBar, Box, Divider, Stack, Toolbar, styled } from '@mui/material';
import { LogoContainer } from 'common/components';
import { useBreakpoints, useElementSize } from 'common/hooks';
import { useAppSelector } from 'store';
import { selectIsHeaderDisplayed } from 'store/features/app';

import { CurrentUserContainer, GoToHelpCenter, MobileMenuButton, TabletMenuButton } from './components';

const SectionWrapper = styled(Box)({
  flex: '1 1 33%',
  display: 'flex',
  alignItems: 'center',
  '& > a': { width: '100%' },
});

type TopBarProps = {
  center?: boolean;
  disableTabletMenuButton?: boolean;
  disableUserSection?: boolean;
  disableLogoInteraction?: boolean;
};

export const TopBar: FC<TopBarProps> = memo(
  ({
    center = false,
    disableTabletMenuButton = false,
    disableUserSection = false,
    disableLogoInteraction = false,
    children,
  }) => {
    const [ref, size] = useElementSize();
    const displayHeader = useAppSelector(selectIsHeaderDisplayed);
    const { isMobile, isTablet } = useBreakpoints();
    const showTabletMenuButton = !disableTabletMenuButton && isTablet;
    const showUserSection = !disableUserSection;

    if (!displayHeader) return null;

    return (
      <>
        <AppBar component="header" position="fixed" color="inherit" data-testid="ContentHeader" ref={ref}>
          <Toolbar>
            {center ? (
              <SectionWrapper justifyContent="center">
                <LogoContainer disableInteraction={disableLogoInteraction} />
              </SectionWrapper>
            ) : (
              <>
                <SectionWrapper>
                  {showTabletMenuButton && (
                    <Box mr={3}>
                      <TabletMenuButton />
                    </Box>
                  )}
                  <Stack justifyContent="center">
                    <LogoContainer disableInteraction={disableLogoInteraction} />
                  </Stack>
                </SectionWrapper>

                {showUserSection && (
                  <Stack direction="row" justifyContent="end" alignItems="center" gap={0.5}>
                    <GoToHelpCenter />
                    {isMobile ? (
                      <MobileMenuButton />
                    ) : (
                      <>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <CurrentUserContainer />
                      </>
                    )}
                  </Stack>
                )}
              </>
            )}
          </Toolbar>
          {children && (
            <Box p={2} pt={0}>
              {children}
            </Box>
          )}
        </AppBar>
        {/**
         * This is needed so we don't have anything behind the fixed position app bar
         * https://mui.com/material-ui/react-app-bar/#fixed-placement
         * */}
        <Toolbar sx={{ height: size.height, flexShrink: 0 }} disableGutters />
      </>
    );
  }
);
