import type { Components, Theme } from '@mui/material';

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { palette, typography } }) => ({
      ...typography['natter-text-sm'],
      minHeight: 48,

      '&.Mui-disabled': {
        '.MuiTypography-root': {
          color: palette.grey[300],
        },

        '.MuiInputAdornment-root': {
          color: palette.grey[300],
        },
      },
    }),
    input: ({ theme: { palette } }) => ({
      '&:not(.MuiSelect-select)': {
        padding: 0,
        caretColor: palette.primary.main,
      },
      '&.Mui-disabled': {
        color: palette.grey[400],
        WebkitTextFillColor: palette.grey[400],
      },
    }),
    sizeSmall: ({ theme: { spacing } }) => ({
      minHeight: 32,
      padding: spacing(0.75, 1),
    }),
  },
};
