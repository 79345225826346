import React, { FC } from 'react';

import { Box } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';
import { useBreakpoints } from 'common/hooks/';
import { CommunitySelectorContainer } from 'community/CommunitySelector';
import { NavigationLinksList } from 'layout/AppNavbar/components/NavigationLinksList';
import { useKeycloakContext } from 'store/context/KeycloakContext';

import { SidebarProfile } from './components';

export const SidebarContent: FC = () => {
  const { isMobile } = useBreakpoints();
  const keycloak = useKeycloakContext();

  const handleLogoutClick = () => {
    keycloak.logout();
  };

  return (
    <Box pb={{ xs: 4, sm: 0 }} overflow="auto" data-testid="SidebarContent">
      <Box mt={3} mx={2}>
        <CommunitySelectorContainer />
      </Box>
      <Box my={1}>
        <NavigationLinksList />
      </Box>
      {isMobile && (
        <Box mx={3} my={2}>
          <SidebarProfile />
        </Box>
      )}

      {isMobile && (
        <Box mx={3} mt={4}>
          <Button variant="outlined" onClick={handleLogoutClick}>
            Log out
          </Button>
        </Box>
      )}
    </Box>
  );
};
