// nosemgrep
export const getRandomNumber = (min: number, max: number): number => Math.random() * (max - min) + min;

// nosemgrep
export const getRandomInteger = (min: number, max: number): number => getRandomNumber(Math.ceil(min), Math.floor(max));

const formatter = new Intl.NumberFormat('en', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumSignificantDigits: 3,
});

export const convertNumberToAbbreviation = (value: number) => formatter.format(value);
