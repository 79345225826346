import React, { FC } from 'react';

import { useTheme } from '@mui/material';
import { PieLabelRenderProps } from 'recharts';

import { CHART_LABEL_FONT_SIZE, CHART_LABEL_FONT_WEIGHT } from '../constants';

type Props = PieLabelRenderProps & {
  cx: number;
  cy: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  midAngle?: number;
  radiusDistance?: number;
};

const RADIAN = Math.PI / 180;

export const PieChartLabel: FC<Props> = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  percent,
  midAngle = 0,
  radiusDistance = 0.5,
  fill,
}) => {
  const { palette } = useTheme();
  const value = percent * 100;
  const radius = innerRadius + (outerRadius - innerRadius) * radiusDistance;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  // slice is too thin, label won't fit
  if (value < 5) return null;

  return (
    <text
      x={x}
      y={y}
      fill={palette.getContrastText(fill)}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={CHART_LABEL_FONT_SIZE}
      fontWeight={CHART_LABEL_FONT_WEIGHT}
      style={{ pointerEvents: 'none' }}
    >
      {`${value.toFixed(0)}%`}
    </text>
  );
};
