import accountSettings from './accountSettings.json';
import common from './common.json';
import devices from './devices.json';
import event from './event.json';
import insightReport from './insightReport.json';
import question from './question.json';
import recurringNatters from './recurringNatters.json';
import techCheck from './techCheck.json';

export const defaultNS = 'common';

export const resources = {
  en: {
    accountSettings,
    common,
    event,
    insightReport,
    question,
    recurringNatters,
    techCheck,
    devices,
  },
} as const;

export const ns: Array<keyof (typeof resources)['en']> = [
  'accountSettings',
  'common',
  'devices',
  'event',
  'insightReport',
  'question',
  'recurringNatters',
  'techCheck',
];
