import React, { FC, useMemo } from 'react';

import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { TooltipContentWrapper } from '../../components';
import { CHART_MIN_HEIGHT, legendWrapperStyle } from '../../constants';
import { useChartAxis, useChartColors, useChartStyles, useTooltip } from '../../hooks';
import { BasicChartProps, ChartType } from '../../types';
import { calculateChartMargins } from '../../utils';
import { calculateDensityPlotData } from './utils';

export const DensityPlot: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  TooltipContent,
  legendFormatter,
}) => {
  const { lastYAxisTickHidden } = useChartStyles();
  const { getColor } = useChartColors();
  const { tickStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const densityPlotData = useMemo(() => calculateDensityPlotData({ data, dataKeys }), [data, dataKeys]);
  const { activeKey, handleMouseEnter } = useTooltip();
  const xTicks = useMemo(
    () => Array.from({ length: (densityPlotData.length - 2) * 2 + 1 }).map((_, index) => -0.5 + index * 0.5),
    [densityPlotData]
  );

  const xTickFormatter = (_value: unknown, index: number) => {
    if (index % 2) return '';
    return `${densityPlotData[index / 2 + 1]?.x0}`;
  };

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <AreaChart
        data={densityPlotData}
        margin={{ ...calculateChartMargins({ labelNames, labelValues }), top: 10 }}
        className={lastYAxisTickHidden}
      >
        <CartesianGrid vertical={false} />
        <XAxis hide dataKey="name" type="number" domain={['dataMin', 'dataMax']} allowDataOverflow={true} />
        <XAxis
          xAxisId="ticks"
          type="number"
          domain={['dataMin', 'dataMax']}
          allowDataOverflow={true}
          tickLine={false}
          axisLine={false}
          ticks={xTicks}
          tick={tickStyles}
          tickFormatter={xTickFormatter}
          label={renderAxisXLabel(labelNames)}
        />
        <YAxis
          tickLine={false}
          axisLine={axisLineStyles}
          interval={0}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          width={40}
        />
        <Tooltip
          cursor={false}
          content={
            <TooltipContentWrapper
              shouldDisplay={({ x1 }) => x1 !== undefined}
              activeDataKey={activeKey}
              TooltipContent={TooltipContent}
              chartType={ChartType.DensityPlot}
            />
          }
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && (
          <Legend verticalAlign="top" align="right" formatter={legendFormatter} wrapperStyle={legendWrapperStyle} />
        )}
        {dataKeys.map((key, dataKeyIndex) => (
          <Area
            key={key}
            dataKey={key}
            type="monotoneX"
            fill={getColor(dataKeyIndex)}
            onMouseEnter={handleMouseEnter(key)}
            fillOpacity={1}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};
