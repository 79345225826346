import { FC } from 'react';

import { compose } from '@reduxjs/toolkit';
import { identity, merge } from 'lodash';

import { withLayout } from '../withLayout';
import { withWhiteBackground } from '../withWhiteBackground';

type PageConfig = {
  withLayout?: boolean;
  withWhiteBackground?: boolean;
};

const DEFAULT_PAGE_CONFIG: PageConfig = {
  withLayout: true,
  withWhiteBackground: false,
} as const;

export const page = (customConfig: PageConfig = DEFAULT_PAGE_CONFIG) => {
  const config = merge(DEFAULT_PAGE_CONFIG, customConfig);

  return compose<FC>(
    config.withLayout ? withLayout : identity,
    config.withWhiteBackground ? withWhiteBackground : identity
  );
};
