import React, { FC, ReactNode } from 'react';

import { AppBar, Divider, Stack, Toolbar } from '@mui/material';
import { LogoContainer } from 'common/components';
import { useElementSize } from 'common/hooks';

import { CommunityLogoContainer } from '../CommunityLogo';
import {
  CommunityLogoWrapper,
  ExitButton,
  SecondRowWrapper,
  TitleDivider,
  TitleElement,
  TitleWrapper,
} from './NavigationBar.styled';

export type NavigationBarProps = {
  title?: ReactNode;
  onExitClick?(): void;
  exitLabel?: string;
  withCommunityLogo?: boolean;
  withActionsDivider?: boolean;
  extraButtons?: ReactNode;
  secondRowChildren?: ReactNode;
};

export const NavigationBar: FC<NavigationBarProps> = ({
  title,
  onExitClick,
  exitLabel = 'Exit',
  withCommunityLogo,
  withActionsDivider,
  children,
  extraButtons,
  secondRowChildren,
}) => {
  const [ref, size] = useElementSize();

  return (
    <>
      <AppBar component="nav" color="inherit" data-testid="NavigationBar" ref={ref}>
        <Toolbar>
          <LogoContainer />

          {withCommunityLogo && (
            <CommunityLogoWrapper data-testid="NavigationBar-communityLogo">
              <CommunityLogoContainer />
            </CommunityLogoWrapper>
          )}

          {title && (
            <TitleWrapper>
              <TitleDivider />

              <TitleElement data-testid="NavigationBar-title" component={typeof title === 'string' ? 'h3' : 'div'}>
                {title}
              </TitleElement>
            </TitleWrapper>
          )}

          {children}

          <Stack direction="row" alignItems="center" gap={1} ml={1}>
            {withActionsDivider && <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />}
            {extraButtons}

            {onExitClick && (
              <ExitButton onClick={onExitClick} data-testid="NavigationBar-exit-button">
                {exitLabel}
              </ExitButton>
            )}
          </Stack>
        </Toolbar>
        {secondRowChildren && <SecondRowWrapper>{secondRowChildren}</SecondRowWrapper>}
      </AppBar>
      {/**
       * This is needed so we don't have anything behind the fixed position app bar
       * https://mui.com/material-ui/react-app-bar/#fixed-placement
       * */}
      <Toolbar sx={{ height: size.height, flexBasis: size.height, flexShrink: 0 }} disableGutters />
    </>
  );
};
