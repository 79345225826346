import React, { FC, useEffect } from 'react';

import { useAppDispatch } from 'store';
import {
  appDrawerHide,
  appDrawerShow,
  appHeaderHide,
  appHeaderShow,
  appHeaderSimpleHide,
  appHeaderSimpleShow,
  appMainPaddingHide,
  appMainPaddingShow,
} from 'store/features/app';

import { useFullPageContainerStyles } from './FullPageContainer.styles';

export const FullPageContainer: FC<{
  centered?: boolean;
  simpleHeader?: boolean;
  noHeader?: boolean;
  noPadding?: boolean;
}> = ({ centered, children, simpleHeader = true, noHeader = false, noPadding = true }) => {
  const classes = useFullPageContainerStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(appDrawerHide());

    return () => {
      dispatch(appDrawerShow());
      dispatch(appHeaderSimpleHide());
      dispatch(appHeaderShow());
      dispatch(appMainPaddingShow());
    };
  }, [dispatch]);

  useEffect(() => {
    if (simpleHeader) {
      dispatch(appHeaderSimpleShow());
    } else {
      dispatch(appHeaderSimpleHide());
    }
  }, [dispatch, simpleHeader]);

  useEffect(() => {
    if (noHeader) {
      dispatch(appHeaderHide());
    } else {
      dispatch(appHeaderShow());
    }
  }, [dispatch, noHeader]);

  useEffect(() => {
    if (noPadding) {
      dispatch(appMainPaddingHide());
    } else {
      dispatch(appMainPaddingShow());
    }
  }, [dispatch, noPadding]);

  return (
    <div className={classes.pageContainer}>
      <div className={classes.contentWrapper}>
        {centered ? <div className={classes.centered}>{children}</div> : children}
      </div>
    </div>
  );
};
